import React from 'react';
import type { IconProps } from './icons.interface';

export const ChevronDown: React.FunctionComponent<IconProps> = ({ className = '', fill = '#666', width = 12, height = 12 }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M256 79.093L225.813 48.907L128 146.72L30.187 48.907L-1.31947e-06 79.093L128 207.093L256 79.093Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
